@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";

/* general start*/
body {
  background: #fff;
  color: #222;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 2;
  margin: 0;
  padding: 0;
}
sub,
sup {
  font-size: 75%;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: 10px;
  left: 10px;
}
sub {
  bottom: 10px;
  right: 10px;
}
ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
ul li {
  padding: 0;
  margin: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
}
h1 {
  font-size: 20px;
  line-height: 1.4;
  color: #222;
  font-family: "Inter", sans-serif;
  font-weight: bold;
}
h2 {
  font-size: 18px;
  line-height: 1.2;
  color: #fff;
  color: #222;
  font-family: "Inter", sans-serif;
  margin-bottom: 10px;
  font-weight: bold;
}
h3 {
  font-size: 26px;
  line-height: 1.3;
  font-weight: 500;
  margin-bottom: 15px;
  color: #222;
  font-family: "Inter", sans-serif;
}
h4 {
  font-size: 20px;
  line-height: 1.3;
  color: #222;
  font-family: "Inter", sans-serif;
  font-weight: 500;
}
h5 {
  font-size: 16px;
  line-height: 1.3;
  color: #222;
  font-family: "Inter", sans-serif;
  font-weight: 600;
}
h6 {
  font-size: 14px;
  line-height: 1.3;
  color: #222;
  font-family: "Inter", sans-serif;
  font-weight: bold;
}
p {
  padding: 0 0 20px 0;
  margin: 0;
}
a {
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  color: var(--primarycolor);
  outline: none;
  text-decoration: none;
  color: #12b76a;
}
a:hover,
a:focus {
  color: #222;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  text-decoration: none !important;
  outline: none !important;
}
a > img {
  line-height: 0;
}
select,
button,
input {
  box-shadow: none;
  outline: none;
}
select:hover,
button:hover,
input:hover,
select:focus,
button:focus,
input:focus {
  box-shadow: none;
  outline: none;
}
/* select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  background-image: url(images/seclect-arrow.png);
  background-repeat: no-repeat;
  background-position: 95% center;
  background-color: rgba(0,0,0,0) !important;
  color: #666;
} */
.form-control {
  background-color: transparent;
  border-left: 0;
  border-top: 0;
  border-right: 0;
  border-radius: 0;
  color: #fff;
  padding-left: 0;
  height: 100%;
  padding: 12px 10px 12px 0;
  transition: all 400ms;
}
.form-control:focus {
  background-color: transparent;
  outline: none;
  box-shadow: none;
  border-color: rgb(2, 0, 22);
  color: #fff;
}
.form-control::placeholder {
  opacity: 1;
  color: #fff;
}

.loginsec {
  background-color: #3b4867;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loginsec .btn1 {
  background-color: #fff;
  border: 0;
  color: #3b4867;
  font-size: 18px;
  font-weight: 600;
  padding: 9px 95px;
  border-radius: 50px;
  transition: all 400ms;
}
.loginsec .btn1:hover {
  background-color: #000000;
  color: #fff;
}
.featureicon {
  min-width: 70px;
  max-width: 70px;
  min-height: 70px;
  max-height: 70px;
  background-color: #dfdede;
}
.featurebox {
  transition: all 400ms;
  border-bottom: 1px solid #ddd;
  border-left: 1px solid #ddd;
  cursor: pointer;
}
.featureicon svg {
  fill: #000;
  transition: all 400ms;
}
/* .featurebox:hover .featureicon {
  background-color: #3b4867;
}
.featurebox:hover .featureicon svg {
  fill: #fff;
} */
.footer {
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: #3b4767;
  z-index: 9;
  position: fixed;
}
.ftrmenubox {
  text-align: center;
}
.ftrmenubox {
  color: #a4a3a3;
  cursor: pointer;
}
.ftrmenubox svg {
  fill: #a4a3a3;
}
.ftrmenubox.active {
  color: #fff;
}
.ftrmenubox.active svg {
  fill: #fff;
}
.recorsec {
  position: fixed;
  bottom: 72px;
  width: 100%;
  left: 0;
  background-color: #fff;
  box-shadow: 0px 0px 12px #ddd;
}

#button-background {
  position: relative;
  background-color: #149557;
  width: 280px;
  height: 40px;
  border: white;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}
#slider {
  transition: width 0.3s, border-radius 0.3s, height 0.3s;
  position: absolute;
  left: 5px;
  background-color: #fff;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
#slider.unlocked {
  transition: all 0.3s;
  width: inherit;
  left: 0 !important;
  height: inherit;
  border-radius: inherit;
  background-color: #951414;
}
.material-icons {
  cursor: default;
  display: flex;
  align-items: center;
  justify-content: center;
}
.slide-text {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: grab;
}
.bottom {
  position: fixed;
  bottom: 0;
  font-size: 14px;
  color: white;
}
.bottom a {
  color: white;
}
.unlocked .locktext {
  font-weight: 600;
  font-size: 16px;
  color: #fff;
  font-style: normal;
}
.pausebtn {
  position: absolute;
  right: 5px;
  cursor: pointer;
}
.cursorgrab {
  cursor: grab;
}
.settinsec .accordion-button {
  background-color: transparent;
  color: #000000;
}
.settinsec .accordion-body {
  border-bottom: 1px solid #ddd !important;
}
.settinsec .accordion-item {
  border: 0;
}
.settinsec .accordion-button:not(.collapsed) {
  box-shadow: none !important;
}
.settinsec .accordion-button:not(.collapsed)::after {
  background-image: url(assets/images/minus.svg);
  width: 30px;
  height: 30px;
  cursor: pointer;
  background-size: 15px;
  background-position: center;
}
.settinsec .accordion-button::after {
  background-image: url(assets/images/plus.svg);
  width: 30px;
  height: 30px;
  cursor: pointer;
  background-size: 15px;
  background-position: center;
}
.settinsec .form-check.form-check-inline {
  display: inline-flex;
  margin-right: 1rem;
  align-items: center;
  gap: 10px;
}
.settinsec .form-check-input {
  margin-top: 0;
}
.settinsec .form-check-input:checked {
  background-color: #000;
  border-color: #000;
}
.settinsec .form-check-input:focus {
  border-color: #000;
  outline: 0;
  box-shadow: none;
}
.settinsec .form-check-input {
  border-color: #000;
}
.settinsec .accordion-header {
  font-size: 16px;
}
.settinsec .form-control {
  color: #000;
}
.settinsec .form-control::placeholder {
  color: #666;
}
.settinsec .accordion-header {
  cursor: pointer;
}
.settinsec .accordion-header {
  line-height: 1.2;
  font-weight: 600;
}
