.custom-loader {
}

.nvh-gap-1 {
  gap: 4px;
}

.nvh-gap-2 {
  gap: 8px;
}

.nvh-gap-3 {
  gap: 16px;
}

.nvh-gap-4 {
  gap: 24px;
}

.loader-center {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00000088;
  z-index: 9999;
}

.loader-center.masked {
  background-color: white;
}

.text-blue {
  color: #007bff;
  font-weight: 500;
}

.app-name {
  font-size: 48px;
  color: white;
}

.app-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #3b4867;
  min-height: 60px;
  color: white;
}

.app-title {
  font-size: 24px;
  font-weight: bold;
}

.loginsec {
  flex-direction: column;
  justify-content: space-evenly;
}

.featurebox-selected .featureicon {
  background-color: #149557;
}

.network-indicator {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #a4a3a3;
  box-shadow: 0 0 5px;
}
.network-indicator.active {
  background-color: #149557;
}

.featurebox-selected .featureicon svg {
  fill: #fff;
}
.featurebox-disabled {
  pointer-events: none;
  cursor: no-drop;
}

.timebox {
  input[type="time"] {
    background-color: #ccc;
  }
}

.timebox-input {
  width: 85px;
  border: none;
  border-bottom: 1px solid lightgray;
  background-color: whitesmoke;
  height: 35px;
  border-radius: 10px;
  padding: 5px;
}
.timebox-input:disabled {
  background-color: lightgray;
  color: black;
}

.featuresec {
  max-height: 80vh;
  overflow: auto;
  padding-bottom: 20vh;
}
.settinsec {
  max-height: 75vh;
  overflow: auto;
  padding-bottom: 25vh;
}

.flex-1 {
  flex: 1;
}

.btn-setting-icon,
.btn-setting-icon:focus,
.btn-setting-icon:hover {
  background-color: transparent;
  border: none;
}

.btn-setting-icon:disabled {
  opacity: 0.3;
  cursor: no-drop;
}
.btn-setting-icon img {
  width: 30px;
}

.btn-setting-icon.btn-edit img {
  color: #007bff;
}

.btn-setting-icon.btn-save img {
  color: #149557;
}
.settinsec .form-control {
  padding-left: 10px;
  padding-right: 10px;
}
